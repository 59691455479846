import indexStyles from '../sass/index.scss';
let commons = require('./utils/commons');
import slideIMG from '../img/slide.png';
import slideXsIMG from '../img/slide-xs.png';
import item1IMG from '../img/item-1.png';
import item2IMG from '../img/item-2.png';
import item3IMG from '../img/item-3.png';
import item4IMG from '../img/item-4.png';
import brand1IMG from '../img/brand-1.png';
import brand2IMG from '../img/brand-2.png';
import brand3IMG from '../img/brand-3.png';
import brand4IMG from '../img/brand-4.png';
import brand5IMG from '../img/brand-5.png';
import brand6IMG from '../img/brand-6.png';
